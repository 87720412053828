@import "./fonts/gelion.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: overlay;
  font-size: 100%;
}

@media (max-width: 900px) {
  html {
    font-size: 80%;
  }
}

@media (max-width: 780px) {
  html {
    font-size: 70%;
  }
}

@media (max-width: 550px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 40%;
  }
}

body {
  margin: 0;
  font-family: 'Gelion Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scrollbar::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #005DA1;        /* color of the tracking area */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #F6A000;    /* color of the scroll thumb */
  border-radius: 7px;       /* roundness of the scroll thumb */
  /*border: 3px solid #005DA1;  !* creates padding around scroll thumb *!*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .appWidth {
    @apply mx-auto w-full text-white overflow-clip;
    /*@apply mx-auto max-w-[1366px] w-[calc(100%-128px)] sm:w-[calc(100%-160px)] 2lg:w-full;*/
  }

  .orange_shadow {
    @apply saturate-0 brightness-100 contrast-150 opacity-40
  }

  .normal_shadow{
    @apply saturate-100 brightness-100 contrast-100 opacity-100
  }

  .form_input{
    @apply border-none outline-none text-darkGray focus:text-white px-2 py-1 placeholder:text-textSecundary focus:bg-textSecundary focus:placeholder:text-white text-[14px] mobile:text-lg
  }

  .fontsize{
    @apply text-[10px] sm:text-[14px] md:text-[16px]
  }

  .hero {
    @apply h-[calc(80vh-60px)] mobile:h-[calc(80vh-5rem)] sm:h-[calc(100vh-5rem)]
  }
}

.svg_gray *{
  fill: #878787 !important;
}

.text_stroke:hover > span {
  -webkit-text-stroke: 1px #FFFFFF;
}

.mobile_stroke > span {
  -webkit-text-stroke: 1px #FFFFFF;
}

 {

}
