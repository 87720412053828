@tailwind components;

@layer components {

    .title{
        @apply font-GelionBold text-7xl text-white tracking-wide
    }
    .subtitle{
        @apply font-GelionBold text-5xl text-white tracking-wide
    }

}

@font-face {
    font-family: 'Gelion Regular';
    src: url('./Gelion/Gelion-Regular.otf') format("opentype");
}
@font-face {
    font-family: 'Gelion Light';
    src: url('./Gelion/Gelion-Light.otf') format("opentype");
}
@font-face {
    font-family: 'Gelion Bold';
    src: url('./Gelion/Gelion-Bold.otf') format("opentype");
}
@font-face {
    font-family: 'Gelion SemiBold';
    src: url('./Gelion/Gelion-SemiBold.otf') format("opentype");
}
@font-face {
    font-family: 'Gelion Black';
    src: url('./Gelion/Gelion-Black.otf') format("opentype");
}
@font-face {
    font-family: 'Gelion Thin';
    src: url('./Gelion/Gelion-Thin.otf') format("opentype");
}
